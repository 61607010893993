(
	() =>
	{
		const collection = document.getElementsByClassName("panel-collapsable");
		for (let i = 0; i < collection.length; i++) {
			const element = collection.item(i);
			element.classList.add("panel-collapsable-js");
			element.firstElementChild.onclick = function (e) {
				(
					e.target.parentElement.classList.contains("panel-heading")
					&&
					e.target.parentElement.parentElement
					||
					e.target.parentElement
				).classList.toggle("panel-collapsable-collapsed")
			};
		}
	}
)();
