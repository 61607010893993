import Swal from "sweetalert2";

require('script-loader!jquery');
require('./bootstrap');
require('./components/panel-collapsable');
require('script-loader!jquery-match-height');
require('script-loader!sticky-kit/dist/sticky-kit.js');
require('script-loader!social-likes');

require('script-loader!echo-js');

require('../img/placeholder.png');
require('../img/logo.png');
require('../img/logo2.png');
require('../img/sk.jpg');
require('../img/two_man.jpg');
require('../img/video-1.jpg');
require('../img/video-2.jpg');
require('../img/video-3.jpg');

import * as Sentry from '@sentry/browser';

window['Sentry'] = Sentry;

function initGlobalMenu()
{
    try {
        if (!/qrator\.(cz|ru)$/.test(document.location.host)) {
            GlobalMenu.init(
              {
                  lc: appLang,
                  toggleBtn: ".global-menu",
              });
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}

$(document).ready(function () {
    Sentry.init(
        {
            dsn: `https://user@${location.hostname}/sentry`,
            denyUrls: [
                'https://www.google-analytics.com/',
                'https://stats.g.doubleclick.net',
            ],
            // bug: https://sentry.web.qrator.dev/organizations/qrator/issues/5618/?project=4
            // fix: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
            ignoreErrors:[
                "Non-Error exception captured",
                "Non-Error promise rejection captured"
            ],
        }
    );

    if (typeof GlobalMenu !== "undefined") {
        initGlobalMenu();
    } else {
        $("#global-menu-script").on("load", function () {
            initGlobalMenu();
        })
    }

    echo.init({
        offset: 1000,
        throttle: 50,
        unload: false,
    });

    $('.carousel').on('slid.bs.carousel', function () {
        echo.render();
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        echo.render();
    });

    document.documentElement.addEventListener("carousel_changed", function (e) {
        echo.render();
    })

    $('.social-likes').socialLikes({
        counters: false
    });

    var player = {
        playVideo: function (offset) {
            if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
                window.onYouTubeIframeAPIReady = function () {
                    player.loadPlayer(offset);
                };

                $.getScript('//www.youtube.com/iframe_api');
            } else {
                player.loadPlayer(offset);
            }
        },
        loadPlayer: function (offset) {
            new YT.Player('video-player-container', {
                height: '550',
                width: '100%',
                videoId: (appLang == 'ru') ? 'Ec2BmXUcPB4' : 'h6ClfiJJYd8',
                playerVars: {
                    disablekb: 1,
                    autoplay: 1,
                    modestbranding: 1,
                    controls: 0,
                    showinfo: 0,
                    start: offset,
                    rel: 0,
                    hd: 1
                }
            });
        }
    };

    $(".video-toggler").click(function () {
        var timeOffset = parseInt($(this).attr('data-time-offset'));
        timeOffset = (timeOffset) ? timeOffset : 0;
        player.playVideo(timeOffset);
        var title = $(this).find('p').text();
        $('#vModal').find('.modal-title').empty().append(title);
    });

    $('#vModal').on('hidden.bs.modal', function () {
        $('.js-video-player_holder').empty().append('<div id="video-player-container"></div>');
    });

    $(".fix-it").stick_in_parent({
        parent: 'body',
        // spacer: false,
        bottoming: false,
        offset_top: 0,
        inner_scrolling: false //true
    }).on('sticky_kit:bottom', function (e) {
        $(this).closest('body').css('position', 'static');
        // $(this).parent().css('position', 'static');
    });

    // equal height
    $('[data-match-children-height]').each(function () {
        var $this = $(this);
        $this.find($this.data().matchChildrenHeight).matchHeight({
            byRow: false
        });
    });

    $('[data-match-children-height]').matchHeight._throttle = 280;

    // relative to mobile menu
    var clickEvent = ('ontouchstart' in window ? 'touchstart' : 'click');

    $('.menu-trigger').on(clickEvent, function () {
        var menu = $(this).parents('.menu');
        menu.hasClass('roll-down') ?
            menu.removeClass('roll-down') && $(document.body).removeClass('mobile-fixed') :
            menu.addClass('roll-down') && $(document.body).addClass('mobile-fixed');
    });

    function menuClose() {
        $('.menu-dropdown.active').removeClass('active');
        $(document.body).off('click', menuClose);
        $(document.body).off('mouseout', menuClose);
    }

    $('.menu-dropdown label').on('click', function (event) {
        $(document.body).off('click', menuClose);
        var menu = $(this).parents('.menu-dropdown');
        if (menu.hasClass('active')) {
            menu.removeClass('active');
        } else {
            $(this).parents('.menu-dropdown').addClass('active');
        }
        $(document.body).on('click', menuClose);
        $(document.body).on('mouseout', menuClose);
        event.stopPropagation();
    });
    // scroll down arrow
    $('.promo-down').click(function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section1').offset().top
        }, 600, 'swing');
    });

    $('.menu-search__lnk').click(function () {
        if ($('#triggerSearch').hasClass('show-search')) {
            $('#triggerSearch').removeClass('show-search')
            return false
        } else {
            $('#triggerSearch').addClass('show-search')
            return false
        }
    })

    // children go active
    $('[data-active-children="true"]').children().on('click', function () {
        $(this).parent().children('.active').removeClass('active');
        $(this).addClass('active');
    });

    // tooltip
    $('.tooltip_o').tooltip({
        template: '<div class="tooltip tooltip_o-bl" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" dir="auto"></div></div>',
        placement: 'right'
    })
    // end tooltip

    $('.tooltip_d').tooltip({
        template: '<div class="tooltip tooltip_o-bl" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" dir="auto"></div></div>',
        placement: 'bottom',
        html: true
    })
    // end tooltip

    // toogle collapse for solution
    $('#ulSolution > li > ul.collapse').on('shown.bs.collapse', function () {
        $('#ulSolution > li > ul.collapse.in').not(this).collapse('hide');
    })
    $('#ulSolution > li > ul > li > ul.collapse').on('shown.bs.collapse', function () {
        $('#ulSolution > li > ul > li > ul.collapse.in').not(this).collapse('hide');
    })
    // end toogle collapse for solution

    // select style
    if ($('.select-stylized').length != 0) {
        $('.select-stylized').styler({
            singleSelectzIndex: '1'
        });
    }
    // end select style

    /*check if the input has any value (if we've typed into it)*/
    $('.form__input').each(function (i, el) {
        if ($(el).val()) {
            $(el).closest('.form__wrapper').addClass('form--filled');
            $(el).closest("form").validate().form();

        } else {
            $(el).closest('.form__wrapper').removeClass('form--filled');
        }
    });

    $('.form__input').blur(function () {
        if ($(this).val()) {
            $(this).closest('.form__wrapper').addClass('form--filled');
        } else {
            $(this).closest('.form__wrapper').removeClass('form--filled');
        }
    });

    // tab be_partner II
    $('.nav-tabs-beP > a').click(function (e) {
        e.preventDefault();
        var self = this,
            href = $(self).attr("href");
        $('.nav-tabs-beP > a.active').removeClass('active');
        $(self).addClass('active');
        $(".tab-content_beP .tab-pane").removeClass('active')
        $(href).addClass('active');
    });

    $('.show-popup').click(function (){
        var storyId = $(this).data('story-id');
        openStory(storyId);
        return false;
    });
});

function openStory(storyId) {
    window.location.hash = `story-${storyId}`;
    Swal.fire({
        html: $('#successStoryContent-' + storyId).html(),
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        customClass: {
            container: 'success-story-popup'
        },
        showClass: {
            popup: 'animated fadeInDown faster',
            icon: 'animated heartBeat delay-1s'
        },
        hideClass: {
            popup: 'animated zoomOut faster',
        },
        willOpen() {
            $('.success-story-carousel').slick("slickPause");
        },
        willClose() {
            window.history.replaceState({}, "", location.pathname)
            $('.success-story-carousel').slick("slickPlay");
        }
    });
}
